import ContainerMain from "src/pages/common/ContainerMain"
import ceo1 from "images/Company/OurPeople/ceoAQuang.png"
import ceo3 from "images/Company/OurPeople/cooChinWee.png"
import OurPeopleIntro from "./OurPeopleIntro"
import "./OurPeople.scss"
import { useFormatter } from "helpers/i18n"

export default function OurPeople() {
  const { __ } = useFormatter()
  const ourPeopleData = [
    {
      id: 1,
      avatar: ceo1,
      name: `${__({ defaultMessage: "Tran Vu Quang" })}`,
      title: `${__({ defaultMessage: "Founder & CEO" })}`,

      description: `${__({
        defaultMessage:
          "We aim to provide the most advanced eco-system and service to support e-commerce and interconnect brands, partners and consumers.  We believe that our talents are the engine for our own success story and greatly emphasize this in our daily working culture. We invest and nurture our talents through comprehensive training, adventurous projects and provide a plethora of opportunities for learning and personal growth. We cultivate an entrepreneurial and ambitious work environment, collaborative work relationships, engaging projects, best-in-class learning and development for e-commerce and provide you with mentorship that allow you to advance critical skills further. <br /> <br /> OnPoint enables you to unlock your own potential and be the best version of yourself!",
      })}`,
    },
    {
      id: 3,
      avatar: ceo3,
      name: "Tee Chin Wee",
      title: `${__({ defaultMessage: "Chief Operating Officer" })}`,
      description: `${__({
        defaultMessage:
          "OnPoint provides a multicultural environment for our people to thrive. Diverse cultural perspectives inspire creativity and drive innovation. It also provides great opportunity for our people to grow professionally.",
      })}`,
    },
  ]

  return (
    <div className="career-our-people">
      <div className="WdZHPaJUsL">
        <div className="section-title svoVqdrGDg">
          {__({
            defaultMessage: "Our People",
          })}
        </div>
        <div className="list-ceo-container">
          {ourPeopleData?.map((data, index) => {
            const style_index = (index % 2) + 1
            return (
              <div className={`list-ceo-${style_index}`}>
                <ContainerMain>
                  <div className={`ceo-${style_index}`}>
                    <div className="ceo-img-container">
                      <img className="img-ceo" src={data.avatar} />
                    </div>
                    <div className="intro">
                      <OurPeopleIntro {...data} />
                    </div>
                  </div>
                </ContainerMain>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
